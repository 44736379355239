import React from 'react';
import { isMobileScreen } from 'utilities';

import SVGFacebook from '../../public/svg/social/facebook.svg';
import SVGInstagram from '../../public/svg/social/instagram.svg';
import SVGYoutube from '../../public/svg/social/youtube.svg';
import SVGLinkedin from '../../public/svg/social/linkedin.svg';
import SVGKiwi from '../../public/svg/icons/kiwi.svg';
import SVGStory from '../../static/svg/story.svg';
import SVGAward from '../../static/svg/awards.svg';
import SVGMountaineer from '../../public/svg/icons/moutaineer.svg';
import SVGKPeople from '../../public/svg/icons/people.svg';
import SVGFAQs from '../../public/svg/icons/faqs.svg';
import SVGReview from '../../public/svg/icons/review.svg';

// Read: https://www.gatsbyjs.org/docs/environment-variables/
// export const API_URL = process.env.GATSBY_API_URL;
export const BASE_URL = process.env.GATSBY_BASE_URL;

// export const IMAGE_API = 'https://hakatours.imgix.net';

export const HEADER_HEIGHT = 80;
export const HEADER_HEIGHT_MOBILE = 64;
export const TOUR_PAGE_NAV_HEIGHT = 55;
// export const GRID_GUTTER_WIDTH = 20;

export const CURRENCY = 'NZD';

export const BOOKING_DEPOSIT = 99;

export const SNOW_BOOKING_DEPOSIT = 200;

// the number of nights before and after the tour a user can book accomm for
export const BOOKING_ACCOMM_NIGHTS = 5;

export const COUPON_PARAM_NAME = 'HTPC';

export const COMMISION_FACTORY = 'cfclick';

export const IDEV_AFFILIATE = 'idev_id';

export const SUBSCRIBED_NEWS_LETTER = 'subscribe';

export const PARTIAL_PAYMENT_DEADLINE = 60; // days

export const PRIVATE_TOURS_URL = 'https://hakatours.com/private-tours/';

export const GERMAN_TOUR_URL = '/tour/nz-nature-safari-tour/';

export const GERMAN_FLAG = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN"
  "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<svg xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 5 3">
  <desc>Flag of Germany</desc>
  <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
  <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
  <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
</svg>`;

export const SCROLL_CONFIG = {
  smooth: true,
  duration: 800,
  offset: isMobileScreen() ? -HEADER_HEIGHT_MOBILE : -HEADER_HEIGHT,
};

export const PAGES = {
  HOME: {
    TITLE: 'Home',
    PATH: '/',
  },
  TOURS: {
    TITLE: 'Tours',
    PATH: '/tours/',
  },
  ACTIVITIES: {
    TITLE: 'Activities',
    PATH: '/activities/',
  },
  INCLUDED_ACTIVITIES: {
    TITLE: 'Included Activities',
    PATH: '/included_activities/',
  },
  DESTINATIONS: {
    TITLE: 'Discover NZ',
    PATH: '/destinations/',
  },
  DISCOUNTS: {
    TITLE: 'Discounts',
    PATH: '/discounts/',
  },
  ABOUT: {
    TITLE: 'About Us',
    PATH: '/about-us/our-trips/',
  },
  WIN: {
    TITLE: 'Win',
    PATH: '/about-us/amplified-giveaway/',
    ICON: <SVGAward />,
  },
  ABOUT_BRAND: {
    TITLE: 'Our Culture',
    PATH: '/about-us/our-culture/',
    ICON: <SVGKiwi />,
  },
  ABOUT_PURPOSE: {
    TITLE: 'Our Purpose',
    PATH: '/about-us/our-purpose/',
    ICON: <SVGKiwi />,
  },
  ABOUT_STORY: {
    TITLE: 'Our Story',
    PATH: '/about-us/our-story/',
    ICON: <SVGStory />,
  },
  ABOUT_VALUES: {
    TITLE: 'Our Values',
    PATH: '/about-us/our-values/',
    ICON: <SVGKPeople />,
  },
  ABOUT_TRIPS: {
    TITLE: 'Our Trips',
    PATH: '/about-us/our-trips/',
    ICON: <SVGMountaineer />,
  },
  ABOUT_PEOPLE: {
    TITLE: 'Our People',
    PATH: '/about-us/our-people/',
    ICON: <SVGKPeople />,
  },
  ABOUT_FAQS: {
    TITLE: 'FAQS',
    PATH: '/about-us/faqs/',
    ICON: <SVGFAQs />,
  },
  ABOUT_REVIEWS: {
    TITLE: 'Reviews',
    PATH: '/about-us/reviews/',
    ICON: <SVGReview />,
  },
  BLOGS: {
    TITLE: 'Hakatours Blog',
    PATH: 'https://hakatours.com/blog/',
  },
  ABOUT_SCHOLARSHIPS: {
    TITLE: 'Māori tour manager scholarships',
    PATH: '/about-us/māori-tour-manager-scholarships/',
    ICON: <SVGReview />,
  },
  ABOUT_AWARDS: {
    TITLE: 'Awards',
    PATH: '/about-us/our-awards/',
    ICON: <SVGAward />,
  },
  INBOUND_OP: {
    TITLE: 'Inbound Services',
    PATH: '/about-us/inbound-tour-operator-services/',
  },
  KATHMANDU_PROMO: {
    TITLE: 'Kathmandu Promotion',
    PATH: '/about-us/kathmandu-snow-promotion/',
  },
  BLOG: {
    TITLE: 'Blog',
    PATH: '/blog/',
  },
  CONTACT: {
    TITLE: 'Contact Us',
    PATH: '/contact-us/',
  },
  LATE_REQUEST: {
    TITLE: 'Late Request',
    PATH: '/late-request/',
  },
  USER: {
    TITLE: 'Your Bookings',
    PATH: '/user/',
  },
  USER_FAVOURITES: {
    TITLE: 'Your Favourites',
    PATH: '/user/favourites/',
  },
  USER_EDIT: {
    TITLE: 'Edit Account',
    PATH: '/user/edit/',
  },
  SIGN_IN: {
    TITLE: 'Sign In',
    PATH: '/user/sign-in/',
  },
  SIGN_UP: {
    TITLE: 'Sign Up',
    PATH: '/user/sign-up/',
  },
  RESET_PASSWORD: {
    TITLE: 'Reset Your Password',
    PATH: '/user/reset-password/',
  },
  TERMS: {
    TITLE: 'Terms and Conditions',
    PATH: '/booking-conditions/',
  },
  PRIVACY: {
    TITLE: 'Privacy Policy',
    PATH: '/privacy-policy/',
  },
  COOKIES: {
    TITLE: 'Cookies',
    PATH: '/cookies-policy/',
  },
  ITINERARY_DOWNLOAD: {
    TITLE: 'Itinerary Download',
    PATH: '/itinerary-download/',
  },
  BOOKINGS: {
    TITLE: 'Book a Tour',
    PATH: '/bookings/',
  },
  BOOKINGS_DETAILS: {
    TITLE: 'Details',
    PATH: '/bookings/traveller-details/',
  },
  BOOKINGS_ESSENTIALS: {
    TITLE: 'Accommodation',
    PATH: '/bookings/essentials/',
  },
  BOOKINGS_ADD_ONS: {
    TITLE: 'Add-ons',
    PATH: '/bookings/add-ons/',
  },
  BOOKINGS_REVIEW: {
    TITLE: 'Review',
    PATH: '/bookings/review/',
  },
  BOOKINGS_PAYMENT: {
    TITLE: 'Payments',
    PATH: '/bookings/payments/',
  },
  BOOKINGS_PAYMENT_SUCCESS: {
    TITLE: 'Payments - Success',
    PATH: '/bookings/payments/success/',
  },
  BOOKINGS_PAYMENT_ERROR: {
    TITLE: 'Payments - Error',
    PATH: '/bookings/payments/error/',
  },
  BOOKINGS_THANK_YOU: {
    TITLE: 'Thank you',
    PATH: '/bookings/thank-you',
  },
};

export const MAIN_NAV = [
  PAGES.TOURS,
  PAGES.DISCOUNTS,
  PAGES.ACTIVITIES,
  PAGES.DESTINATIONS,
  PAGES.ABOUT,
  PAGES.BLOG,
  PAGES.LOGIN,
];

export const BOOKING_NAV = [
  PAGES.BOOKINGS_DETAILS,
  PAGES.BOOKINGS_ADD_ONS,
  PAGES.BOOKINGS_ESSENTIALS,
  PAGES.BOOKINGS_REVIEW,
  PAGES.BOOKINGS_PAYMENT,
];

export const TOUR_PAGE_NAV = {
  SUMMARY: {
    NAME: 'Summary',
    TITLE: 'Summary',
  },
  ITINERARY: {
    NAME: 'Itinerary',
    TITLE: 'Itinerary',
  },
  INCLUDED: {
    NAME: 'WhatsIncluded',
    TITLE: 'What’s included?',
  },
  ADDONS_UPGRADES: {
    NAME: 'AddonsUpgrades',
    TITLE: 'Add-ons & upgrades',
  },
  REVIEWS: {
    NAME: 'guestReviews',
    TITLE: 'Guest Reviews',
  },
  DATES: {
    NAME: 'AvailableDates',
    TITLE: 'Available dates',
  },
  FAQS: {
    NAME: 'FAQs',
    TITLE: 'FAQs',
  },
};

export const CONTACT = {
  PHONE: '+64 9 520 5593',
  EMAIL: 'info@hakatours.com',
  ADDRESS: 'Gillies Ave Office Park Suite 101,\n\n27 Gillies Ave, Newmarket, Auckland 1023, New Zealand',
};

export const SOCIAL = {
  FACEBOOK: {
    TITLE: 'Facebook',
    URL: 'https://www.facebook.com/HakaTours',
    ICON: <SVGFacebook />,
  },
  INSTAGRAM: {
    TITLE: 'Instagram',
    URL: 'https://www.instagram.com/hakatours/',
    ICON: <SVGInstagram />,
  },
  YOUTUBE: {
    TITLE: 'Youtube',
    URL: 'https://www.youtube.com/user/HakaTours',
    ICON: <SVGYoutube />,
  },
  LINKEDIN: {
    TITLE: 'Linkedin',
    URL: 'https://www.linkedin.com/company/haka-tours',
    ICON: <SVGLinkedin />,
  },
};

// export const BREAKPOINTS = {
//   XS: 0,
//   SM: 576,
//   MD: 768,
//   LG: 992,
//   XL: 1220,
//   XXL: 1400,
// };

// export const ANIMATION_DURATION = {
//   BASE: 200,
//   COLLAPSE: 300,
// };

export const STATES = {
  ACTIVE: 'is-active',
  COLLAPSING: 'is-collapsing',
  COLLAPSED: 'is-collapsed',
  EXPANDING: 'is-expanding',
  EXPANDED: 'is-expanded',
  LOADING: 'is-loading',
  LOADED: 'is-loaded',
  SUBMITTING: 'is-submitting',
  SUBMITTED: 'is-submitted',
  VISIBLE: 'is-visible',
  PREV: 'is-prev',
  NEXT: 'is-next',
  HAS_MESSAGE: 'has-message',
  SUCCESS: 'is-success',
  ERROR: 'is-error',
  LOCKED: 'is-locked',
  UPDATING: 'is-updating',
};

export const META_TITLE = 'New Zealand Tours | Small Group Adventures | Haka Tours';

export const META_DATA = [
  {
    name: 'description',
    content: 'Book your New Zealand trip of a lifetime on a small group bus tour with all accommodation included! Haka Tours offer adventure, ski & mountain bike tours of NZ – choose from a range of 3–24 day tours.',
  },
  {
    name: 'og:title',
    content: META_TITLE,
  },
  {
    name: 'og:description',
    content: 'Book your New Zealand trip of a lifetime on a small group bus tour with all accommodation included! Haka Tours offer adventure, ski & mountain bike tours of NZ – choose from a range of 3–24 day tours.',
  },
];

export const TRIP_ADVISOR_URL = 'https://www.tripadvisor.co.nz/Attraction_Review-g255106-d12839846-Reviews-Haka_Tours-Auckland_Central_North_Island.html'; // eslint-disable-line
export const GOOGLE_REIVEW_URL = 'https://www.google.com/search?q=haka+tours&oq=haka+tours&aqs=chrome..69i57j69i60l3j69i59j0.1872j0j7&sourceid=chrome&ie=UTF-8#lrd=0x6d3188e0cdbeaf5f:0x407889bcf60575d1,3,,,'; // eslint-disable-line

export const FOOTER_BANNER_ATTRIBUTES = {
  link: '/adventure-vs-haka-plus',
  title: '',
  subtitle: '',
  backgroundImage: 'https://hakatours-staging.imgix.net/5afcfd73369dcd00010000f1/33.UncoveredNZ_Day17_FranzJosef_original.jpg',
  customElements: `
    <div class="c-heading c-heading--h1">Haka Adventure vs</div>
    <div class="c-heading c-heading--h1">Haka Plus</div>
    <div class="c-heading c-heading--h2">Learn more</div>
  `,
  modifier: ['overlay-50'],
};
